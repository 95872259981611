<template lang="pug">
  .shops-settings-tab
    .shop-settings(v-if="isShopFormShown")
      ShopsForm(
        ref="selectedShop"
        :shop-id="selectedShopId"
        :has-edit-permission="hasEditPermission()"
        @back="hideShopForm"
        @remove-shop="removeShop"
      )
    .shops-settings-wrapper(v-else)
      .top-bar-wrapper
        TopBar(
          :inventory-groups-loading="inventoryGroupsLoading"
          :inventory-groups="inventoryGroups"
          :prefectures-loading="prefecturesLoading"
          :prefectures="prefectures"
          :filters="filters"
          :has-edit-permission="hasEditPermission()"
          @change-filters="changeFilters"
          @add-new="showShopForm"
        )
      .shops-table-wrapper
        AppOverlayLoader(:state="shopsLoading")
        ShopsTable(
          :shops="shops"
          :sorting-data="sorting"
          :has-edit-permission="hasEditPermission()"
          @select-shop="selectShop"
          @remove-shop="removeShop"
          @sorting="changeSorting"
        )
      AppPagination(
        :current-page="pagination.current_page"
        :total="pagination.total_count"
        :per-page="pagination.per_page"
        @change-pagination-data="changePagination"
      )
</template>

<script>
  // mixins
  import withPermissions from "@/mixins/withPermissions"
  import withStoreModule from "@/mixins/withStoreModule"
  import withSorting from "@/mixins/withSorting"
  import withConfirmation from "@/mixins/withConfirmation"

  // stores
  import shopsModule from "@/config/store/company_system/shops_settings/shops/basic_settings"
  import prefecturesModule from "@/config/store/prefectures"
  import inventoryGroupsModule from "@/config/store/company_system/shops_settings/inventory_groups"
  import accessSettingModule from "@/config/store/company_system/shops_settings/shops/access_settings"
  import timeSettingModule from "@/config/store/company_system/shops_settings/shops/time_settings"

  // misc
  import { appDebounce } from "@/helpers/common"

  // components
  const shopsMixin = withStoreModule(shopsModule, {
    resetState: true,
    name: "companiesShops",
    readers: {
      shops: "items",
      filters: "filters",
      shopsLoading: "loading",
      pagination: "pagination",
      sorting: "sorting"
    },
    actions: {
      fetchShops: "FETCH_ITEMS",
      deleteShop: "DELETE_ITEM"
    },
    mutations: {
      setShopDirty: "SET_DIRTY",
      setFilters: "SET_FILTERS",
      resetFilters: "RESET_FILTERS",
      setPagination: "SET_PAGINATION_DATA",
      resetCurrentPage: "RESET_CURRENT_PAGE",
      setSorting: "SET_SORTING"
    }
  })

  const accessSettingMixin = withStoreModule(accessSettingModule, {
    name: "companiesShopAccessSetting",
    mutations: { setAccessSettingDirty: "SET_DIRTY" }
  })

  const timeSettingMixin = withStoreModule(timeSettingModule, {
    name: "companiesShopTimeSettingMixin",
    mutations: { setTimeSettingDirty: "SET_DIRTY" }
  })

  const inventoryGroupsMixin = withStoreModule(inventoryGroupsModule, {
    name: "companiesInventoryGroup",
    readers: {
      inventoryGroups: "items",
      inventoryGroupsLoading: "loading"
    },
    actions: { fetchInventoryGroups: "FETCH_ITEMS" }
  })

  const prefecturesMixin = withStoreModule(prefecturesModule, {
    name: "prefectures",
    readers: { prefectures: "items", prefecturesLoading: "loading" },
    actions: { fetchPrefectures: "FETCH_ITEMS" }
  })

  export default {
    components: {
      TopBar: () => import("./TopBar"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppPagination: () => import("@/components/elements/AppPagination"),
      ShopsTable: () => import("./ShopsTable"),
      ShopsForm: () => import("./ShopsForm")
    },

    mixins: [
      shopsMixin,
      accessSettingMixin,
      timeSettingMixin,
      inventoryGroupsMixin,
      prefecturesMixin,
      withSorting,
      withConfirmation,
      withPermissions
    ],

    created() {
      this.debouncedFetchShops = appDebounce(() => this.fetchShops())
    },

    data() {
      return {
        isShopFormShown: false,
        selectedShopId: null
      }
    },

    async mounted() {
      this.fetchInventoryGroups({ pagination: { _disabled: true } })
      this.fetchShops()
      this.fetchPrefectures()
    },

    beforeRouteLeave(to, _from, next) {
      this.beforeRouteLeaveHandler({
        to,
        next,
        isChanges: this.useConfirm,
        exitHandler: () => {
          this.setShopDirty(false)
          this.setAccessSettingDirty(false)
          this.setTimeSettingDirty(false)
        }
      })
    },

    computed: {
      useConfirm() {
        return this.hasEditPermission() && this.$refs.selectedShop?.hasUnsavedChanges
      }
    },

    methods: {
      selectShop(shop) {
        this.selectedShopId = shop.id
        this.showShopForm()
      },

      removeShop(shop) {
        this.$confirm({
          title: this.$t("company_system.shop_settings.delete_shop_confirmation"),
          resolve: {
            handler: () => {
              this.deleteShop(shop.id)
            }
          }
        })
      },

      showShopForm() {
        this.isShopFormShown = true
      },

      hideShopForm() {
        this.isShopFormShown = false
        this.selectedShopId = null
        this.resetFilters()
        this.resetCurrentPage()
        this.fetchShops()
      },

      changeFilters(filters) {
        this.setFilters(filters)
        this.resetCurrentPage()
        this.debouncedFetchShops()
      },

      changePagination(paginationData) {
        this.setPagination({ ...this.pagination, ...paginationData })
        this.debouncedFetchShops()
      },

      changeSorting(sorting) {
        this.setSorting(sorting)
        this.resetCurrentPage()
        this.debouncedFetchShops()
      }
    }
  }
</script>

<style lang="sass" scoped>

  .shops-settings-wrapper
    margin-top: 24px

  .shops-table-wrapper
    position: relative
    margin-top: 16px
</style>
